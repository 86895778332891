
import { useContext, useEffect } from "react";
import { AppContext } from "./context/context";
import { useLocation } from "react-router-dom";

export function PageNotFound() {
    useEffect(() => {
        onPageNotFound();
    }, [])
    const state: any = useContext(AppContext);

    const onPageNotFound = () => {
        state.messageForParent('close');
        window.location.replace(`${process.env.REACT_APP_MATHBUDDY_URL}/page-not-found.html`);
    }
    return (<></>)
}

// Component to dynamically load styles
export function DynamicStyleLoader() {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== '/learnActivity') {
            // Dynamically import styles
            import('bootstrap/dist/css/bootstrap.min.css');
            import('./global.css');
        }
    }, [location.pathname]);

    return (<></>)
};